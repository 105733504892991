import { handleActions, combineActions } from 'redux-actions'
import { setClub } from 'utils/localstorage'
import {
  actionsTypesGenerator,
  onRequest,
  onSuccess,
  onFailure,
} from 'utils/reduxHelpers'
import initialState from '../initialState'

const namespace = 'THEME'
const FETCH_THEME = actionsTypesGenerator(`FETCH_${namespace}`)
const FETCH_ALL_CLUBS = actionsTypesGenerator(`FETCH_${namespace}_ALL_CLUBS`)
const SET_CURRENT_THEME = 'SET_CURRENT_THEME'

export const getAllClubs = () => ({
  types: FETCH_ALL_CLUBS,
  callAPI: {
    method: 'GET',
    entity: 'User',
    path: `/clubs`,
  },
})

export const getClubTheme = (id = 1) => ({
  types: FETCH_THEME,
  callAPI: {
    method: 'GET',
    entity: 'User',
    path: `/clubs/${id}`,
  },
})

// Update the current Theme in Redux
export const setCurrentTheme = (theme) => ({
  type: SET_CURRENT_THEME,
  payload: {
    currentTheme: theme
  },
})

export default handleActions(
  {
    [combineActions(FETCH_THEME.request)]: onRequest,
    [combineActions(FETCH_ALL_CLUBS.request)]: onRequest,
    [FETCH_THEME.success]: (state, { response: { data = {} } }) => {
      setClub(data)

      return onSuccess({
        ...state,
        current: { currentTheme: data.theme.theme.toUpperCase() },
      })
    },
    [combineActions(FETCH_THEME.success)]: (state, { response: { data } }) => {
      setClub(data)

      return onSuccess({
        ...state,
        current: { currentTheme: data.theme.theme.toUpperCase() },
      })
    },
    [combineActions(FETCH_THEME.failure)]: onFailure,

    [SET_CURRENT_THEME]: (state, { payload }) => ({
      ...state,
      current: payload, // Update the current Theme in Redux like { currentTheme: payload }
    }),
  },

  initialState.themes
)
