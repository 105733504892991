import React, { forwardRef } from 'react'
import { styled, withStyles } from '@material-ui/core/styles'
import { Menu, Box as MuiBox } from '@material-ui/core'
import { LinkWithRef } from 'components'

export const StyledMenu = withStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 12px 0 ${theme.palette.grey[400]}`,
    '& .MuiList-root': {
      padding: theme.spacing(0.6, 0, 0.2, 0),
    },
  },
}))(
  forwardRef((props, ref) => (
    <Menu
      ref={ref}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))
)

export const Box = styled(MuiBox)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.04)',
  '& span.MuiBox-root': {
    padding: theme.spacing(0.3, 0.4),
    margin: theme.spacing(0, 0.4),
    fontSize: 13,
    width: 66,
    display: 'inline-block',
    textAlign: 'center',
    borderRadius: 7,
  },
  '& span.MuiBox-root.active': {
    background: theme.palette.primary.main,
    color: 'var(--white)',
  },
  '& span.MuiBox-root:hover': {
    cursor: 'pointer',
    background: theme.palette.primary.main,
    color: 'var(--white)',
  },
}))

export const StyledLink = styled(LinkWithRef)(({ theme }) => ({
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}))
