import React from 'react'
import { Box, Button, TableCell, TableRow, Typography } from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down('sm')]: {
    noIcon: {
      position: 'static !important',
      background: 'transparent !important',
      width: '100% !important',
    },
  },
}))

const NoIcon = ({ handleOpen }) => {
  const classes = useStyles()

  return (
    <TableRow align="center">
      <TableCell variant="body" className={classes.noIcon} colSpan={7}>
        <Box width="100%" mr={1} textAlign="center" py={4} px={2}>
          <Box display="flex" justifyContent="center" py={2}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => handleOpen('', false)}
            >
              <Translate id="button.plan-event" />
            </Button>
          </Box>
          <Typography variant="body2" component="p">
            <Translate id="events.plan-event" />
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  )
}

NoIcon.defaultProps = {
  handleOpen: () => 0,
}

NoIcon.propTypes = {
  handleOpen: PropTypes.func,
}

export default NoIcon
