import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  MenuItem,
  Box,
  makeStyles,
  TablePagination,
  TextField,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { FormControl, InputAdornment, Typography } from '@mui/material'
import SearchIcon from '@material-ui/icons/Search'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { Translate } from 'react-localize-redux'
import { LoadingSpinner, SelectedMenu } from 'components'
import { setSelectedClub } from 'redux/modules/clubs'
import { setCurrentTheme } from 'redux/modules/themes'
import { debounce } from 'lodash'
import TeamListItem from './components/TeamListItem'
import Header from './components/Header'
import CreateTeamPopup from './components/CreateTeamPopup'
import { fetchClubs, fetchClubTeams } from './api'

const useStyles = makeStyles((theme) => ({
  box: {
    background: 'white',
    margin: '0 1rem',
    padding: '2rem 3rem',
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      margin: '0',
      padding: '1rem',
    },
  },
  paper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  label: {
    marginRight: '1rem!important',
    fontWeight: '900!important',
    fontFamily: 'Helvetica !important',
    fontSize: '1rem !important',
  },
  select: (props) => ({
    marginRight: '1rem',
    borderRadius: '.5rem!important',
    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: `${props.primarycolor} !important`,
    },
  }),
  addTeamBtn: (props) => ({
    borderRadius: '.7rem',
    color: 'black',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderColor: `${props.primarycolor} !important`,
    padding: '.2rem .7rem',
    whiteSpace: 'nowrap',
  }),
  formControl: {
    flex: '1',
    width: '420px',
    marginRight: 15,
    marginBottom: '0 !important',
    [theme.breakpoints.down('md')]: {
      marginRight: '1rem!important',
      width: '100%',
    },
  },
  ageClasses: {
    margin: '1.5rem 0',
  },
  ListOfTeams: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '25px',
    margin: '0 auto',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  filterButton: (props) => ({
    margin: '.5rem 2rem .5rem 0',
    borderRadius: '.7rem',
    padding: '.1rem 1.5rem',
    color: 'black',
    borderColor: props.primarycolor,
    '&:hover': {
      color: props.primarycolor,
      borderColor: props.primarycolor,
    },
    '&.selected': {
      backgroundColor: props.primarycolor,
      color: 'white',
    },
  }),
  italicText: {
    fontStyle: 'italic',
    paddingBottom: '.2rem',
    fontSize: '.8rem !important',
  },
  buttonBoxContainer: {
    display: 'flex',
  },
  sortIcon: {
    cursor: 'pointer',
    margin: '0 10px',
  },
  tablePagination: {
    margin: '10px 0',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
  },
  search: {
    marginBottom: 0,
  },
}))

const TeamsDashboard = () => {
  const dispatch = useDispatch()
  const [clubs, setClubs] = useState([])
  const [selectedClubUuid, setSelectedClubUuid] = useState('')
  const [teams, setTeams] = useState([])
  const [sortAsc, setSortAsc] = useState(true)
  const [ageClasses, setAgeClasses] = useState([])
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoadingTeams, setIsLoadingTeams] = useState(false)
  const [isLoadingClubs, setIsLoadingClubs] = useState(false)
  const [open, setOpen] = useState(false)
  const [tags, setTags] = useState('')
  const [teamsPerPage, setTeamsPerPage] = useState(16)
  const [totalTeams, setTotalTeams] = useState(0)
  const [teamSearchInput, setTeamSearchInput] = useState('')
  const [filters, setFilters] = useState({
    searchQuery: '',
    ageClass: null,
    teamTag: null,
    page: 0,
  })
  const selectedClubTheme = clubs.find((club) => club.uuid === selectedClubUuid)
  const selectedClub = clubs.find((club) => club.uuid === selectedClubUuid)

  let clubTheme = {}
  if (selectedClubTheme?.theme_temp) {
    try {
      clubTheme = JSON.parse(selectedClubTheme.theme_temp)
    } catch (error) {
      console.error('Error parsing JSON:', error)
    }
  }
  const commonFetchParams = {
    include: 'ageClass,activePlayersCount,logo,seasons,teamTag',
  }

  useEffect(() => {
    setIsLoadingClubs(true)
    fetchClubs({ include: 'ageClasses,teamTags' })
      .then((res) => {
        if (!res.data || res.data.length === 0) {
          setErrorMessage('No clubs available')
        } else {
          setClubs(res.data)
          setSelectedClubUuid(res.data[0].uuid)
          dispatch(setSelectedClub(res.data[0])) // Updates Redux state
          dispatch(setCurrentTheme(JSON.parse(res.data[0].theme_temp).theme))
        }
      })
      .catch((error) => {
        console.error('Error fetching clubs', error)
        setErrorMessage('Error fetching clubs')
      })
      .finally(() => {
        setIsLoadingClubs(false)
      })
  }, [])

  const fetchTeams = (allFilters) => {
    setIsLoadingTeams(true)
    setTeams([])

    fetchClubTeams(selectedClubUuid, allFilters)
      .then((res) => {
        setAgeClasses(selectedClub.relationships.age_classes)
        setTags(selectedClub.relationships.team_tags)
        setTeams(res.data)
        setTotalTeams(res.meta.total)
      })
      .catch((error) => {
        console.error('Error fetching teams', error)
        setErrorMessage('Error fetching teams.')
      })
      .finally(() => {
        setIsLoadingTeams(false)
      })
  }

  useEffect(() => {
    if (selectedClubUuid) {
      const filtersParams = {
        ...commonFetchParams,
        per_page: teamsPerPage,
        page: filters.page + 1,
        sort: sortAsc ? 'name' : '-name',
      }

      if (filters.ageClass) {
        filtersParams['filter[ageClass.uuid]'] = filters.ageClass
      }
      if (filters.teamTag) {
        filtersParams['filter[teamTag.uuid]'] = filters.teamTag
      }
      if (filters.searchQuery) {
        filtersParams['filter[name]'] = filters.searchQuery
      }

      fetchTeams(filtersParams)
    }
  }, [filters, selectedClubUuid, sortAsc, teamsPerPage])

  const handleFilterChange = (type, value) => {
    setSortAsc(true)
    setTeamSearchInput('')

    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [type]: value,
        page: 0,
        searchQuery: '',
      }

      if (type === 'ageClass') {
        updatedFilters.ageClass = value
      } else if (type === 'teamTag') {
        updatedFilters.teamTag = value
      }

      return updatedFilters
    })
  }

  const handleSelectedClubChange = (event) => {
    setTeamSearchInput('')
    setSelectedClubUuid(event.target.value)
    const tempClub = clubs.find((club) => club.uuid === event.target.value)
    dispatch(setSelectedClub(tempClub))
    dispatch(setCurrentTheme(JSON.parse(tempClub.theme_temp).theme))
    setFilters((prevFilters) => ({
      ...prevFilters,
      ageClass: null,
      teamTag: null,
      searchQuery: '',
      page: 0,
    }))
    setSortAsc(true)
  }

  useEffect(() => {
    if (selectedClub) {
      setTags(selectedClub.relationships.team_tags)
    }
  }, [selectedClub])

  const debouncedSearch = useCallback(
    debounce((search) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        searchQuery: search,
        page: 0,
      }))
    }, 400),
    []
  )

  const handleSearchChange = (event) => {
    const search = event.target.value
    setTeamSearchInput(search)
    debouncedSearch(search)
  }

  const handleChangePage = (event, newPage) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: newPage,
    }))
  }

  const handleChangeTeamsPerPage = (event) => {
    setTeamsPerPage(parseInt(event.target.value, 10))
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: 0,
    }))
  }

  const handleSortToggle = () => {
    setSortAsc((prevSortAsc) => !prevSortAsc)
  }

  const handleApplyTeamFilters = (ageClassUuid, tagUuid) => {
    const updatedTagUuid = tagUuid || null
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        ageClass: ageClassUuid,
        teamTag: updatedTagUuid,
        page: 0,
      }
    })
  }

  const handleTeamModalOpen = () => {
    setOpen(true)
  }

  const handleTeamModalClose = () => {
    setOpen(false)
  }

  const userHasCreateTeamPermission = clubs
    .find((club) => club.uuid === selectedClubUuid)
    ?.can.includes('create-team')

  const classes = useStyles(clubTheme)

  const isLoading = isLoadingClubs || isLoadingTeams

  return (
    <>
      <Header clubTheme={clubTheme} />
      <Box className={classes.box}>
        <Box className={classes.paper}>
          <Box className={classes.wrapper}>
            {clubs.length > 1 && (
              <>
                <Typography noWrap className={classes.label}>
                  <Translate id="myteams.clubs" />
                </Typography>
                <SelectedMenu
                  id="clubs"
                  value={selectedClubUuid}
                  className={classes.formControl}
                  onChange={handleSelectedClubChange}
                  label={<Translate id="myteams.clubs" />}
                >
                  {clubs &&
                    clubs.map((club) => (
                      <MenuItem key={club.uuid} value={club.uuid}>
                        {club.name}
                      </MenuItem>
                    ))}
                </SelectedMenu>
              </>
            )}
            <Typography noWrap className={classes.label}>
              <Translate id="myteams.teams" />
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <TextField
                variant="outlined"
                value={teamSearchInput}
                onChange={handleSearchChange}
                className={classes.search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {sortAsc ? (
              <ArrowDownwardIcon
                onClick={handleSortToggle}
                className={classes.sortIcon}
              />
            ) : (
              <ArrowUpwardIcon
                onClick={handleSortToggle}
                className={classes.sortIcon}
              />
            )}
          </Box>
          <Box className={classes.buttonBoxContainer}>
            <CreateTeamPopup
              handleClose={handleTeamModalClose}
              open={open}
              selectedClubUuid={selectedClubUuid}
              ageClasses={ageClasses}
              selectedClub={selectedClub}
              onApplyTeamFilters={handleApplyTeamFilters}
            />
            {userHasCreateTeamPermission && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.addTeamBtn}
                onClick={handleTeamModalOpen}
              >
                <Translate id="myteams.add-team" />
              </Button>
            )}
          </Box>
        </Box>
        <Box className={classes.ageClasses}>
          <Typography className={classes.italicText}>
            <Translate id="myteams.select-age-class" />
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            className={`${classes.filterButton} ${
              filters.ageClass === null ? 'selected' : ''
            }`}
            onClick={() => handleFilterChange('ageClass', null)}
          >
            <Translate id="team.all-teams" />
          </Button>
          {ageClasses &&
            ageClasses.map((ageClass) => (
              <Button
                key={ageClass.uuid}
                variant="outlined"
                color="primary"
                value={ageClass.uuid}
                className={`${classes.filterButton} ${
                  filters.ageClass === ageClass.uuid ? 'selected' : ''
                }`}
                onClick={() => handleFilterChange('ageClass', ageClass.uuid)}
              >
                {ageClass.name}
              </Button>
            ))}
        </Box>
        {selectedClub && !!selectedClub.enable_team_tags && (
          <Box className={classes.ageClasses}>
            <Typography className={classes.italicText}>
              <Translate id="myteams.select-tag" />
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              className={`${classes.filterButton} ${
                filters.teamTag === null ? 'selected' : ''
              }`}
              onClick={() => handleFilterChange('teamTag', null)}
            >
              <Translate id="team.all-teams" />
            </Button>
            {tags &&
              tags.map((tag) => (
                <Button
                  key={tag.uuid}
                  variant="outlined"
                  color="primary"
                  value={tag.uuid}
                  className={`${classes.filterButton} ${
                    filters.teamTag === tag.uuid ? 'selected' : ''
                  }`}
                  onClick={() => handleFilterChange('teamTag', tag.uuid)}
                >
                  {tag.name}
                </Button>
              ))}
          </Box>
        )}
        <Box>
          <TablePagination
            count={totalTeams}
            page={filters.page}
            rowsPerPage={teamsPerPage}
            onRowsPerPageChange={handleChangeTeamsPerPage}
            component="div"
            onPageChange={handleChangePage}
            rowsPerPageOptions={[16, 24, 36]}
            className={classes.tablePagination}
          />
          {errorMessage && (
            <Typography variant="body1" color="error">
              {errorMessage}
            </Typography>
          )}
          {!isLoading && !errorMessage && teams.length === 0 && (
            <Typography variant="body1">
              <Translate id="myteams.no-teams-match-filter" />
            </Typography>
          )}
          {isLoading && (
            <Box display="flex" justifyContent="center" padding={10}>
              <LoadingSpinner />
            </Box>
          )}
          <Box className={classes.ListOfTeams}>
            {!isLoading &&
              !errorMessage &&
              teams.length > 0 &&
              teams.map((team) => (
                <TeamListItem
                  key={team.uuid}
                  team={team}
                  clubTheme={clubTheme}
                  selectedClub={selectedClub}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default  TeamsDashboard
