import {
  Box,
  CardHeader,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
} from '@material-ui/core'
import { SubLink } from 'containers'
import React, { Fragment } from 'react'
import { Translate } from 'react-localize-redux'
import { formateDate, getHourMinute } from 'utils/formatDate'
import useTeamFeature from 'hooks/useTeamFeature'
import PropTypes from 'prop-types'
import { EVENT_ROUTE } from 'constants/routes'
import TEAM_FEATURE_FLAGS from 'constants/teamFeatureFlags'

const RecentEventsWidget = ({ pastEvents }) => {
  const { enabled: hasEditMatchEnabled } = useTeamFeature(
    TEAM_FEATURE_FLAGS.create_match_enabled
  )

  const getEventLink = (pastEvent) => {
    if (pastEvent.relation === 'training') {
      return `/trainings/${pastEvent.uuid}`
    }
    if (hasEditMatchEnabled) {
      return `/matches/${pastEvent.uuid}`
    }

    return EVENT_ROUTE
  }

  const getEventlocation = (event) => {
    if (event.relation === 'training') {
      return event.short_address || ''
    }

    return event.home === 0 ? (
      <Translate id="matches.away-game" />
    ) : (
      <Translate id="matches.home-game" />
    )
  }

  return (
    pastEvents.length > 0 && (
      <Box mt={3}>
        <Paper>
          <CardHeader
            title={
              <Typography variant="h4" component="h4">
                <Translate id="events.recent-events" />
              </Typography>
            }
          />
          <List>
            {pastEvents &&
              pastEvents.map(
                (pastEvent, index) =>
                  index <= 4 && (
                    <Fragment key={pastEvent.id || index}>
                      <Divider />
                      <ListItem>
                        <Box
                          width="100%"
                          display="flex"
                          justifyContent="between"
                          alignItems="center"
                          textAlign="left"
                        >
                          <Box component="div" mr={2}>
                            <Typography variant="h4" component="h4">
                              {getHourMinute(pastEvent.date)}
                            </Typography>
                            <Typography variant="h6" component="h6">
                              {`${formateDate(pastEvent.date)[4]} ${
                                formateDate(pastEvent.date)[3]
                              }`}
                            </Typography>
                          </Box>
                          <Box component="div">
                            <SubLink to={getEventLink(pastEvent)}>
                              <Typography variant="h4" component="h4">
                                {pastEvent.relation === 'training'
                                  ? `Training`
                                  : pastEvent.opponent}
                              </Typography>
                              <Typography
                                variant="h6"
                                fontSize={14}
                                component="h6"
                              >
                                {getEventlocation(pastEvent)}
                              </Typography>
                            </SubLink>
                          </Box>
                          <Box component="div" ml="auto" textAlign="right">
                            <Typography variant="h4" component="h4">
                              {`${pastEvent.available_players}/${pastEvent.total_players}`}
                            </Typography>
                            <Typography
                              variant="h6"
                              fontSize={14}
                              component="h6"
                            >
                              <Translate id="home.available" />
                            </Typography>
                          </Box>
                        </Box>
                      </ListItem>
                    </Fragment>
                  )
              )}
          </List>
        </Paper>
      </Box>
    )
  )
}

RecentEventsWidget.propTypes = {
  pastEvents: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
}

export default RecentEventsWidget
